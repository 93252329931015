import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/olisl",
    name: "seemapindex",
    component: () => import("../views/SandLandSEE.vue"),
  },
];
const router = new VueRouter({
  routes,
});

export default router;
